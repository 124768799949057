import React, { useState } from "react"
import A6K from "../images/a6k.png"
import Ancubator from "../images/ancubator.png"
import translate from "../i18n/translate"
import Blooming from "../images/blooming.png"
import ProjectCard from "./reusable-components/projectCard"
import "../styles/project.css"
import ListOfProjects from "./listOfProjects"

const Projects = () => {
  const [project, setProject] = useState("jobswap")
  function changeProject(name) {
    setProject(name)
  }
  return (
    <section className="container-fluid projects" id="projects">
      <div className="container">
        <div className="row">
          <h1 className="col-12 d-none d-lg-block">
            {translate("heading-projects")}
          </h1>
        </div>
      </div>
      <div className="container">
        <ListOfProjects changeProject={changeProject} project={project} />
        <div className="row projectContainer justify-content-center">
          {project === "a6k" ? (
            <ProjectCard
              key={1}
              translateDescription={translate("a6k")}
              title={"A6K"}
              link={"https://www.a6k.be/home/"}
              imgSrc={A6K}
            />
          ) : project === "ancubator" ? (
            <ProjectCard
              key={2}
              translateDescription={translate("ancubator")}
              title={"ANCUBATOR"}
              link={"https://www.ancubator.com"}
              imgSrc={Ancubator}
            />
          ) : project === "blooming" ? (
            <ProjectCard
              key={3}
              translateDescription={translate("blooming")}
              title={"Blooming Riders"}
              link={"#"}
              imgSrc={Blooming}
            />
          ) : (
            <ProjectCard
              key={4}
              translateDescription={translate("adminBlooming")}
              title={"JobSwap"}
              link={"#"}
              imgSrc={Blooming}
            />
          )}
        </div>
      </div>
    </section>
  )
}

export default Projects
