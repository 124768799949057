import React, { useLayoutEffect } from "react"
import translate from "../i18n/translate"
import "../styles/education.css"

const Education = () => {
  useLayoutEffect(() => {
    const elementsWeInteract = document.querySelectorAll(".interact")
    const onScroll = () => {
      const winHeight = window.outerHeight
      const interactionSize = winHeight / 1.8
      ;[...elementsWeInteract].map(el => {
        const elPosY = el.getBoundingClientRect().y - winHeight * 0.7 // get the position to the element from scroll
        // Interaction zone
        if (elPosY < 0 && elPosY > interactionSize - interactionSize * 2) {
          const newPosY = -elPosY
          const rate = (1 - (newPosY / interactionSize).toFixed(2)) * 100
          const opacity = (1 - rate / 100) * 1.2
          leftOrRight(el, rate)
          el.style.opacity = opacity
        } else if (elPosY < interactionSize - interactionSize * 2) {
          leftOrRight(el, "100%")
          el.style.opacity = 1
        }
      })
    }
    window.addEventListener("scroll", onScroll)
    function leftOrRight(el, rate) {
      if (el) {
        el.style.transform = `translateY(${rate}px)`
      }
    }
    return () => window.removeEventListener("scroll", onScroll)
  }, [])

  return (
    <section className="container education" id="exp">
      <div className="row">
        <h1 className="col-12 col-lg-4 d-none d-lg-block">
          {translate("heading-education")}
        </h1>
        <div className="col-12 col-lg-8 message">
          <p id="message">{translate("message")}</p>
        </div>
      </div>
      <div className="row educationContainer">
        <div className="col-12 container profPath ">
          <div className="row">
            <div className="col-12 col-lg-6 offset-lg-6 workContainer lightBox firstBox interact">
              <h2>Technofuture TIC</h2>
              <p>2019, {translate("country")}</p>
              <p>{translate("description-technofuture")} </p>
            </div>
            <div className="col-12 col-lg-6 workContainer darkBox secondBox interact">
              <h2>BeCode</h2>
              <p>
                {translate("date-becode")}, {translate("country-city")}
              </p>
              <p>{translate("description-becode")}</p>
            </div>
            <div className="col-12 col-lg-6 offset-6 workContainer lightBox thirdBox interact">
              <h2>Blooming riders</h2>
              <p>
                {translate("date-blooming")}, {translate("country")}
              </p>
              <p>{translate("description-blooming")}</p>
            </div>
            <div className="col-12 col-lg-6 workContainer darkBox next interact">
              <h2>What's next?..</h2>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Education
