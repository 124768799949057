import React from "react"
import LogoA6k from "./reusable-components/logo-a6k"
import LogoAncubator from "./reusable-components/logo-ancubator"
import LogoJobSwap from "./reusable-components/logo-jobSwap"
import LogoBlooming from "./reusable-components/logoBlooming"

function ListOfProjects({ changeProject, project }) {
  return (
    <div className="row preview justify-content-center">
      <button
        className={
          project === "a6k"
            ? "selected col-4 col-md-2"
            : "notSelected col-4 col-md-2"
        }
        onClick={() => changeProject("a6k")}
      >
        <LogoA6k />
      </button>
      <button
        className={
          project === "ancubator"
            ? "selected col-4 col-md-2"
            : "notSelected col-4 col-md-2"
        }
        onClick={() => changeProject("ancubator")}
      >
        <LogoAncubator />
      </button>
      <button
        className={
          project === "blooming"
            ? "selected col-4 col-md-2"
            : "notSelected col-4 col-md-2"
        }
        onClick={() => changeProject("blooming")}
      >
        <LogoBlooming />
      </button>
      <button
        className={
          project === "jobswap"
            ? "selected col-4 col-md-2"
            : "notSelected col-4 col-md-2"
        }
        onClick={() => changeProject("jobswap")}
      >
        <LogoJobSwap />
      </button>
    </div>
  )
}

export default ListOfProjects
