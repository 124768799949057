import React from "react"
import translate from "../../i18n/translate"
import LogoA6k from "./logo-a6k"
import LogoAncubator from "./logo-ancubator"
import LogoJobSwap from "./logo-jobSwap"
import LogoBlooming from "./logoBlooming"

function ProjectCard({ translateDescription, title, link, imgSrc }) {
  console.log(title)
  console.log(title === "Blooming Riders" || "admin Panel BR")
  return (
    <div className="col-11 col-md-9 container mainContainer" key={title}>
      <div className="row">
        <h2 className="col-12">{title}</h2>
        <div className="col-12 col-md-3 img-container">
          {title === "Blooming Riders" ? (
            <LogoBlooming />
          ) : title === "JobSwap" ? (
            <LogoJobSwap />
          ) : title === "A6K" ? (
            <LogoA6k />
          ) : (
            <LogoAncubator />
          )}
        </div>
        <div className="col-12 col-md-9 projectInfo">
          <p>{translateDescription}</p>
          <button>
            <a href={link} target="_blank" rel="noopener noreferrer">
              {translate("button-visitSite")}
            </a>
          </button>
        </div>
      </div>
    </div>
  )
}

export default ProjectCard
