import React, { useState } from "react"
import Layout from "../components/reusable-components/layout"
import SEO from "../components/seo"
import About from "../components/about"
import Education from "../components/education"
import Projects from "../components/projects"
import { I18nProvider, LOCALES } from "../i18n"
import "bootstrap/dist/css/bootstrap.css"
import "../styles/style.css"
import "../styles/animations.css"

const IndexPage = () => {
  const [activeLangue, setActiveLangue] = useState(LOCALES.ENGLISH)

  function handleLangue(val) {
    setActiveLangue(() => {
      return val
    })
  }
  return (
    <I18nProvider locale={activeLangue}>
      <Layout activeLangue={activeLangue} handleLangue={handleLangue}>
        <SEO title="portfolio" />
        <About />
        <Education />
        <Projects />
      </Layout>
    </I18nProvider>
  )
}

export default IndexPage
