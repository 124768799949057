import React from "react"
import translate from "../i18n/translate"
import LogoAnimated from "./logoAnimated"
import video from "../images/MARV5UFSL61JVJBM_new.mp4"

const About = () => {
  return (
    <section className="about" id="about">
      <div className="container">
        <div className="row aboutContainer">
          <div className="col-12 col-md-10 animation">
            <h1> {translate("greeting-1")} </h1>
            <h1> {translate("greeting-2")} </h1>
            <h1> {translate("greeting-3")} </h1>
            <div className="overlay"></div>
          </div>
          <div className="col-2 d-none d-lg-block">
            <LogoAnimated />
          </div>
          <div className="col-12 col-lg-7 skills">
            <ul>
              <li>JavaScript</li>
              <li>Reactjs</li>
              <li>Next.js</li>
              <li>Gatsby</li>
              <li>Node.js</li>
            </ul>
            <ul>
              <li>HTML</li>
              <li>CSS</li>
              <li>SASS</li>
              <li>Bootstrap</li>
              <li>Materialize</li>
            </ul>
            <ul>
              <li>Git</li>
              <li>Figma</li>
            </ul>
          </div>
          <div className="col-12 col-md-5 videoContainer">
            <video autoPlay loop muted>
              <source src={video} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
        </div>
      </div>
    </section>
  )
}

export default About
